// @flow
import React from 'react';
import Hero from '../Hero';
import Container from '../Container';
import ContactDetails from './Details';
import ContactForm from './Form';
import './styles.css';

type Props = {
  onSubmit: (name: string, email: string, message: string) => void
};

export default function Contact(props: Props) {
  return (
    <section>
      <Hero
        title="Contáctenos"
        subtitle="Envianos tus consultas y/o sugerencias"
        background="#689f38"
      />

      <Container className="Contact">
        {/* Contact details */}
        <article className="Contact__left">
          <h2 className="Contact__title">Información de contacto</h2>
          <p className="Contact__blurb">
            Diríjase personalmente a nuestras oficinas comerciales o
            telefónicamente de lunes a viernes de 8:00 a 12:00 y de 16:00 a
            20:00 hs.
          </p>

          <ContactDetails className="Contact__details" />
        </article>

        {/* Contact form */}
        <article className="Contact__right">
          <h2 className="Contact__title">Envíenos sus comentarios</h2>
          <p className="Contact__blurb">
            Complete el formulario de contacto con su consulta, y lo
            contactaremos a la brevedad. Recuerde que todos los campos son
            obligatorios.
          </p>

          <ContactForm className="Contact__form" onSubmit={props.onSubmit} />
        </article>
      </Container>
    </section>
  );
}
