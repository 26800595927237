import { SHOW_ALERT, HIDE_ALERT } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_ALERT:
      return state.concat([action.payload]);

    case HIDE_ALERT:
      return state.filter(alert => alert.id !== action.payload.id);

    default:
      return state;
  }
}
