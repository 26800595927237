// @flow
import { combineReducers } from 'redux';
import ads from './ads';
import alerts from './alerts';
import classifieds from './classifieds';
import obituaries from './obituaries';
import pages from './pages';
import paginationIndex from './pagination';
import pharmacy from './pharmacy';
import posts from './posts';
import roster from './roster';
import spinner from './spinner';
import weather from './weather';

const rootReducer = combineReducers({
  ads,
  alerts,
  classifieds,
  obituaries,
  pages,
  paginationIndex,
  pharmacy,
  posts,
  roster,
  spinner,
  weather
});

export default rootReducer;
