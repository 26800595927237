// @flow
import React from 'react';
import './styles.css';

type Props = {
  title: string,
  subtitle?: string,
  background: string
};

export default function Hero({ title, subtitle, background }: Props) {
  return (
    <header className="Hero" style={{ backgroundColor: background }}>
      <h1 className="Hero__title">{title}</h1>
      {subtitle && <p className="Hero__subtitle">{subtitle}</p>}
    </header>
  );
}
