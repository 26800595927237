// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../Container';
import sadFaceImage from './sad.svg';
import './styles.css';

export default function NoMatch() {
  return (
    <Container className="No-Match">
      <header className="No-Match__header">
        <img
          src={sadFaceImage}
          className="No-Match__header-image"
          alt="Error 404"
        />
        <h1 className="No-Match__header-title">
          Error 404: Página no encontrada
        </h1>
      </header>

      <p>
        La página a la que está intentando acceder no existe o ha dejado de
        existir en nuestros servidores. Esto puede deberse a:
      </p>

      <ul>
        <li>Que haya escrito la dirección en forma incorrecta.</li>
        <li>
          Que el vínculo en el que hizo click esté desactualizado o apunte a una
          dirección equivocada.
        </li>
        <li>
          Que la página que busca haya sido cambiada de lugar, actualizada y/o
          borrada.
        </li>
      </ul>

      <p>
        Haga <Link to="/">click aquí</Link> para regresar a la página principal,
        o <Link to="/contacto">contáctenos</Link> si piensa que se trata de un
        problema.
      </p>
    </Container>
  );
}
