// @flow
import React from 'react';
import Hero from '../Hero';
import Container from '../Container';
import WeatherDetails from './Details';
import type { WeatherType } from '../../types';

type Props = {
  weather: WeatherType
};

export default function WeatherLayout(props: Props) {
  return (
    <section>
      <Hero
        title="Clima"
        subtitle="Mediciones de la Estación Meteorológica Local"
        background="#2383c4"
      />

      <Container>
        <WeatherDetails {...props.weather} />
      </Container>
    </section>
  );
}
