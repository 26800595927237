// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import { bootstrap } from '../actions/bootstrap';
import { hideAlert } from '../actions/alerts';
import App from '../components/App';
import Analytics from 'react-router-ga';
import ScrollToTop from '../components/ScrollToTop';
import type { AlertType, WeatherType, ReduxStateType, Dispatch } from '../types';

// Components
import NewsFeedContainer from '../containers/NewsFeed';
import PostsContainer from '../containers/Posts';
import AboutContainer from '../containers/About';
import LiveContainer from '../containers/Live';
import RosterContainer from '../containers/Roster';
import ContactContainer from '../containers/Contact';
import WeatherContainer from '../containers/Weather';
import ClassifiedsContainer from '../containers/Classifieds';
import ObituariesContainer from '../containers/Obituaries';
import NoMatch from '../components/404';

type Props = {
  // React own props
  children?: React.Node,

  // React Router location object
  location: {
    action: string,
    hash: string,
    key: string,
    pathname: string,
    query: any,
    search: string
  },

  // Redux actions
  bootstrap: () => void,
  hideAlert: (id: number) => void,

  // Redux state
  alerts: Array<AlertType>,
  spinner: boolean,
  weather: WeatherType
};

class AppContainer extends React.Component<Props> {
  componentWillMount() {
    this.props.bootstrap();
  }

  render() {
    const { children, ...props } = this.props;

    return (
      <Analytics id="UA-108164667-1">
        <ScrollToTop>
          <App {...props}>
            <Switch>
              <Route exact path="/" component={NewsFeedContainer} />
              <Route exact path="/institucional" component={AboutContainer} />
			  <Route exact path="/live" component={LiveContainer} />
              <Route exact path="/clasificados" component={ClassifiedsContainer} />
              <Route exact path="/necrologicos" component={ObituariesContainer} />
              <Route exact path="/turnos" component={RosterContainer} />
              <Route exact path="/clima" component={WeatherContainer} />
              <Route exact path="/contacto" component={ContactContainer} />
              <Route exact path="/noticias/:id" component={PostsContainer} />
              <Redirect path="/noticias" to="/" />
              <Redirect path="/canal" to="/" />
              <Redirect path="/mobile" to="/" />
              <Route exact path="*" component={NoMatch} />
            </Switch>
          </App>
        </ScrollToTop>
      </Analytics>
    );
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    alerts: state.alerts,
    spinner: state.spinner,
    weather: state.weather
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ bootstrap, hideAlert }, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppContainer)
);
