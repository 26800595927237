import { INCREMENT_PAGINATION_INDEX } from '../actions/types';

const initialState = 1;

export default function(state = initialState, action) {
  switch (action.type) {
    case INCREMENT_PAGINATION_INDEX:
      return state + 1;

    default:
      return state;
  }
}
