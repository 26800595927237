// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import find from 'lodash/find';
import get from 'lodash/get';
import { fetchPages } from '../actions/pages';
import About from '../components/About';
import type { PageType, ReduxStateType, Dispatch } from '../types';

type Props = {
  fetchPages: () => void,
  pages: Array<PageType>
};

class AboutContainer extends React.Component<Props> {
  componentWillMount() {
    if (this.props.pages.length === 0) {
      this.props.fetchPages();
    }
  }

  render() {
    const page = find(this.props.pages, { slug: 'institucional' });
    const body: string = get(page, 'body');

    return <About content={body} />;
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    pages: state.pages
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ fetchPages }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutContainer);
