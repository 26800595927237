// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Dropdown from '../Dropdown';
import openIcon from './open.svg';
import closeIcon from './close.svg';
import './styles.css';

// Items within the "TV por cable" popover menu
const GRILLA_LINKS = [
  {
    title: 'TV analógica',
    url: 'https://canalcoop.nyc3.digitaloceanspaces.com/grilla/analogica062024.png',
    isExternal: true
  },
  {
    title: 'ISDB-t',
    url: '',
    isExternal: true
  }
];

// Items within the "YouTube" popover menu
const YOUTUBE_LINKS = [
  {
    title: 'Canal oficial',
    url: 'https://www.youtube.com/channel/UC3feXV0z0qus4paOuq5YAvA',
    isExternal: true
  },
  {
    title: 'Archivo videos',
    url: 'https://www.youtube.com/c/Canal6CooperativoFreyre/videos',
    isExternal: true
  }
];

// Items within the "En vivo" popover menu
const LIVE_LINKS = [
  {
    title: 'Ver en vivo',
    url: 'http://streaming.freyre.com.ar/',
    isExternal: true
  }
];

type Props = {};
type State = {
  expanded: boolean
};

export default class Navigation extends React.Component<Props, State> {
  state = {
    expanded: false // Navigation list starts off being hidden on mobile
  };

  // Toggle list visibility, works only on mobile.
  // This gets passed in to each Dropdown instance as the menu
  // needs to get collapsed once the user clicks on an item.
  toggle = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  };

  collapse = () => {
    this.setState(prevState => ({
      expanded: false
    }));
  };

  render() {
    // Display hamburguer menu if navigation menu is closed, and a close icon if it's expanded.
    const menuIcon = this.state.expanded ? closeIcon : openIcon;
    const listClassName = classnames('Navigation__list', {
      'Navigation__list--expanded': this.state.expanded,
      'Navigation__list--collapsed': !this.state.expanded
    });

    return (
      <nav className="Navigation">
        {' '}
        {/* Hamburguer menu icon */}{' '}
        <button onClick={this.toggle} className="Navigation__hamburger-menu">
          <img
            src={menuIcon}
            alt="Menu de Navegación"
            title="Menu de Navegación"
            className="Navigation__hamburger-menu-icon"
          />
        </button>
        {/* Navigation menu, always visible on desktop, can be toggled on mobile */}{' '}
        <ul className={listClassName}>
          <li className="Navigation__list-item">
            <Dropdown className="Navigation__link" items={LIVE_LINKS} collapseMenu={this.collapse}>
              En vivo{' '}
            </Dropdown>{' '}
          </li>
          <li className="Navigation__list-item">
            <Dropdown
              className="Navigation__link"
              items={YOUTUBE_LINKS}
              collapseMenu={this.collapse}
            >
              YouTube{' '}
            </Dropdown>{' '}
          </li>
          <li className="Navigation__list-item">
            <Dropdown
              className="Navigation__link"
              items={GRILLA_LINKS}
              collapseMenu={this.collapse}
            >
              Grilla{' '}
            </Dropdown>{' '}
          </li>
          <li className="Navigation__list-item">
            <Link to="/turnos" className="Navigation__link" onClick={this.collapse}>
              Turnos{' '}
            </Link>{' '}
          </li>
          <li className="Navigation__list-item">
            <Link to="/clasificados" className="Navigation__link" onClick={this.collapse}>
              Clasificados{' '}
            </Link>{' '}
          </li>
          <li className="Navigation__list-item">
            <Link to="/necrologicos" className="Navigation__link" onClick={this.collapse}>
              Necrológicos{' '}
            </Link>{' '}
          </li>
          <li className="Navigation__list-item">
            <Link to="/contacto" className="Navigation__link" onClick={this.collapse}>
              Contacto{' '}
            </Link>{' '}
          </li>{' '}
        </ul>{' '}
      </nav>
    );
  }
}
