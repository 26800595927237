// @flow
import { RECEIVE_POSTS } from './types';
import { incrementPaginationIndex } from './pagination';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { parsePost } from '../utils/helpers';
import { ENDPOINT_GET_POSTS_BY_PAGE } from '../utils/constants';
import type { PostType, PostsResponseType, ReceivePostsActionType, ThunkAction } from '../types';

function receivePosts(posts: Array<PostType>): ReceivePostsActionType {
  return {
    type: RECEIVE_POSTS,
    payload: {
      posts
    }
  };
}

export function fetchPosts(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    const paginationIndex = getState().paginationIndex;
    const paginationSize = 14;
    const endpoint = `${ENDPOINT_GET_POSTS_BY_PAGE}/${paginationIndex}/size/${paginationSize}`;

    fetch(endpoint)
      .then(response => response.json())
      .then((response: PostsResponseType) => {
        const posts = response.data.map(parsePost);

        dispatch(receivePosts(posts));
        dispatch(incrementPaginationIndex());
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(
          showAlert('Ocurrió un error mientras intentábamos cargar las últimas noticias.', 'error')
        );
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch posts from the API`, error);
      });
  };
}
