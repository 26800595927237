// @flow
import { fetchPosts } from './posts';
import { fetchWeather } from './weather';
import { fetchAds } from './ads';
import type { ThunkAction } from '../types';

// Fetch weather data every 5 minutes (in milliseconds).
const WEATHER_POLLING_INTERVAL = 5 * 60 * 1000;

export function bootstrap(): ThunkAction {
  return dispatch => {
    // Fetch all the data we need to hydrate the app on bootstrap.
    dispatch(fetchPosts());
    dispatch(fetchWeather());
    dispatch(fetchAds());

    // Fetch weather data every `WEATHER_POLLING_INTERVAL` ms.
    setInterval(() => dispatch(fetchWeather()), WEATHER_POLLING_INTERVAL);
  };
}
