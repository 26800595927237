// @flow
import React from 'react';
import './styles.css';

type Props = {
  onClick: () => void
};

export default function LoadMore(props: Props) {
  return (
    <button className="Load-More" onClick={props.onClick}>
      Cargar más noticias
    </button>
  );
}
