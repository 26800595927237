import { RECEIVE_WEATHER } from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_WEATHER: {
      return action.payload;
    }

    default:
      return state;
  }
}
