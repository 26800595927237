// @flow
import get from 'lodash/get';
import { RECEIVE_PHARMACY } from './types';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { ENDPOINT_GET_PHARMACY } from '../utils/constants';
import { parseDate } from '../utils/helpers';
import type {
  PharmacyType,
  ReceivePharmacyActionType,
  PharmacyResponseType,
  ThunkAction
} from '../types';

function receivePharmacy(pharmacy: PharmacyType): ReceivePharmacyActionType {
  return {
    type: RECEIVE_PHARMACY,
    payload: {
      pharmacy
    }
  };
}

export function fetchPharmacy(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    fetch(ENDPOINT_GET_PHARMACY)
      .then(response => response.json())
      .then((response: PharmacyResponseType) => {
        const pharmacy = {
          name: get(response, 'data.name'),
          phone: get(response, 'data.phone'),
          address: get(response, 'data.address'),
          from: parseDate(get(response, 'data.from')),
          to: parseDate(get(response, 'data.to')),
          isException: get(response, 'data.is_exception')
        };

        dispatch(receivePharmacy(pharmacy));
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(
          showAlert('Ocurrió un error mientras intentábamos cargar la farmacia de turno.', 'error')
        );
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch pharmacy roster from the API`, error);
      });
  };
}
