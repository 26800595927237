// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import includes from 'lodash/includes';
import { getVideoId } from '../../utils/helpers';
import Ad from '../Ad';
import Preview from './Preview';
import LoadMore from './LoadMore';
import type { PostType, AdType } from '../../types';
import './styles.css';

type Props = {
  posts: Array<PostType>,
  ads: Array<AdType>,
  onLoadMore: () => void
};

function renderPostPreview(post: PostType, index: number) {
  const images = post.media.filter(item => item.type === 'image');
  const videos = post.media.filter(item => item.type === 'video');
  let background;

  if (images.length > 0) {
    background = images[0].url;
  } else if (videos.length > 0) {
    const videoId = getVideoId(videos[0].url);

    background = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }

  return (
    <Link to={`/noticias/${post.id}`} className="News-Feed__item" key={index}>
      <Preview
        background={background}
        title={post.title}
        excerpt={post.excerpt}
        category={post.categoryName}
      />
    </Link>
  );
}

export default function NewsFeed(props: Props) {
  const firstTwoPosts = props.posts.slice(0, 2);
  const nextFourPosts = props.posts.slice(2, 6);
  const otherPosts = props.posts.slice(6);

  const largeAds = props.ads.filter(ad => includes(['web-lg', 'all'], ad.type));
  const smallAds = props.ads.filter(ad => ad.type === 'web-sm');

  return (
    <section className="News-Feed">
      {/* First two posts plus hero Ad */}
      <div className="News-Feed__hero-container">
        {firstTwoPosts.map(renderPostPreview)}
        <div className="News-Feed__hero-ad">
          <Ad pool={largeAds} />
        </div>
      </div>

      {/* Second row of posts */}
      <div className="News-Feed__all-other-posts">{nextFourPosts.map(renderPostPreview)}</div>

      {/* Banners */}
      <div className="News-Feed__small-ads-container">
        <span className="News-Feed__small-ad">
          <Ad pool={smallAds} />
        </span>
        <span className="News-Feed__small-ads-separator" />
        <span className="News-Feed__small-ad">
          <Ad pool={smallAds} />
        </span>
      </div>

      {/* All other posts */}
      <div className="News-Feed__all-other-posts">{otherPosts.map(renderPostPreview)}</div>

      <div className="News-Feed__load-more">
        <LoadMore onClick={props.onLoadMore} />
      </div>
    </section>
  );
}

NewsFeed.defaultProps = {
  ads: [],
  posts: [],
  onLoadMore: () => {}
};
