// @flow
import React from 'react';
import Hero from '../Hero';
import List from './List';
import Page from '../Page';
import Pharmacy from '../Pharmacy';
import Container from '../Container';
import type { RosterEntryType, PharmacyType, PageType } from '../../types';
import './styles.css';

type Props = {
  page: PageType,
  pharmacy: PharmacyType,
  roster: Array<RosterEntryType>
};

export default function Roster(props: Props) {
  return (
    <section>
      <Hero
        title="Turnos"
        subtitle="Emergencias, Farmacias de Turno y Teléfonos Útiles"
        background="#13987e"
      />

      <div className="Roster__list-container">
        <div className="Roster__list">
          <Pharmacy {...props.pharmacy} />
          <List entries={props.roster} />
        </div>
      </div>

      <Container>
        <Page title={props.page.title} body={props.page.body} className="Roster__phones" />
      </Container>
    </section>
  );
}

Roster.defaultProps = {
  page: {
    title: null,
    body: null
  },
  pharmacy: {},
  roster: []
};
