// @flow
import React from 'react';
import classnames from 'classnames';
import icons from './icons/mapping';
import type { RosterEntryType } from '../../../types';
import './styles.css';

type Props = RosterEntryType & {
  className?: string
};

export default function RosterEntry(props: Props) {
  const icon = icons[props.activity];
  const entryClassName = classnames('Roster-Entry', props.className);

  return (
    <div className={entryClassName}>
      <div className="Roster-Entry__left-column">
        <img src={icon} alt={props.activity} className="Roster-Entry__icon" />
      </div>

      <div className="Roster-Entry__right-column">
        <div className="Roster-Entry__activity">{props.activity}</div>
        <div className="Roster-Entry__details">{props.phone}</div>
      </div>
    </div>
  );
}
