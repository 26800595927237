// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClassifieds } from '../actions/classifieds';
import Classifieds from '../components/Classifieds';
import type { ClassifiedType, ReduxStateType, Dispatch } from '../types';

type Props = {
  fetchClassifieds: () => void,
  classifieds: Array<ClassifiedType>
};

class ClassifiedsContainer extends React.Component<Props> {
  componentWillMount() {
    if (this.props.classifieds.length === 0) {
      this.props.fetchClassifieds();
    }
  }

  render() {
    return <Classifieds classifieds={this.props.classifieds} />;
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    classifieds: state.classifieds
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ fetchClassifieds }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassifiedsContainer);
