// @flow
import { RECEIVE_PAGES } from './types';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { parseDate } from '../utils/helpers';
import { ENDPOINT_GET_PAGES } from '../utils/constants';
import type { PageType, ReceivePagesActionType, PagesResponseType, ThunkAction } from '../types';

function receivePages(pages: Array<PageType>): ReceivePagesActionType {
  return {
    type: RECEIVE_PAGES,
    payload: {
      pages
    }
  };
}

export function fetchPages(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    fetch(ENDPOINT_GET_PAGES)
      .then(response => response.json())
      .then((response: PagesResponseType) => {
        const pages = response.data.map(page => ({
          slug: page.slug,
          title: page.title,
          body: page.body,
          updatedAt: parseDate(page.updated_at)
        }));

        dispatch(receivePages(pages));
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(showAlert('Ocurrió un error mientras intentábamos cargar la página.', 'error'));
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch pages from the API`, error);
      });
  };
}
