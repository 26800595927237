// @flow
import React from 'react';
import Carousel from './Carousel';
import Share from './Share';
import Container from '../Container';
import { stripHtmlTags } from '../../utils/helpers';
import type { PostType } from '../../types';
import './styles.css';

type Props = PostType;

export default function Post(props: Props) {
  const mediaSubmitted = props.media.length > 0;

  // `props.body` is stringified HTML markup that comes from the API.
  // We need to do this because when the body field is left empty,
  // its content defaults to "<p><br></p>" which is truthy.
  const bodySubmitted = stripHtmlTags(props.body);

  // Each category has its own colour. Use that as the background colour for the category badge.
  const customCategoryStyles = {
    backgroundColor: props.categoryColor
  };

  // Image to display when sharing the page on Facebook.
  const shareImage =
    mediaSubmitted && props.media.filter(item => item.type === 'image').length > 0
      ? props.media.filter(item => item.type === 'image')[0].url
      : 'http://static.freyre.com.ar/splash.jpg';

  return (
    <Container className="Post">
      <h1 className="Post__title">{props.title}</h1>
      <p className="Post__excerpt">{props.excerpt}</p>

      <aside className="Post__aside">
        <span className="Post__details">
          <span className="Post__details__label" style={customCategoryStyles}>
            {props.categoryName}
          </span>
          <span className="Post__details__label">{props.date}</span>
        </span>

        <Share title={props.title} url={window.location.href} />
      </aside>

      {(mediaSubmitted || bodySubmitted) && <hr className="Post__ruler" />}

      {mediaSubmitted && <Carousel slides={props.media} />}

      {bodySubmitted && (
        <div className="Post__body" dangerouslySetInnerHTML={{ __html: props.body }} />
      )}

      {/* Open Graph Markup to enable sharing on Facebook with meaningful metadata */}
      <meta property="og:url" content={`http://www.freyre.com.ar/noticias/${props.id}`} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.excerpt} />
      <meta property="og:image" content={shareImage} />
      <meta property="og:locale" content="es_LA" />
    </Container>
  );
}

Post.defaultProps = {
  media: []
};
