// @flow
import React, { Fragment } from 'react';
import Hero from '../Hero';
import Classified from './Classified';
import Container from '../Container';
import NoResults from '../NoResults';
import type { ClassifiedType } from '../../types';
import './styles.css';

type Props = {
  classifieds: Array<ClassifiedType>
};

export default function Classifieds(props: Props) {
  return (
    <Fragment>
      <Hero title="Clasificados" background="#c6a433" />
      <Container className="Classifieds__content">
        {props.classifieds.length === 0 ? (
          <NoResults />
        ) : (
          props.classifieds.map((classified, index) => (
            <div className="Classifieds__item" key={index}>
              <Classified {...classified} />
            </div>
          ))
        )}
      </Container>
    </Fragment>
  );
}
