// @flow
import React from 'react';
import sample from 'lodash/sample';
import type { AdType } from '../../types';
import './styles.css';

type Props = {
  pool: Array<AdType>
};

type State = {
  timestamp: number
};

// Rotate banners every 10 seconds.
const BANNER_ROTATION_PERIOD = 10000;

export default class Ad extends React.Component<Props, State> {
  static defaultProps = {
    pool: []
  };

  interval = null;
  imageElement = null;

  state = {
    timestamp: Date.now()
  };

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ timestamp: Date.now() }),
      BANNER_ROTATION_PERIOD
    );
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  render() {
    if (this.props.pool.length === 0) {
      return null;
    }

    let randomAd = sample(this.props.pool);

    while (this.imageElement && this.imageElement.src === randomAd.imageUrl) {
      randomAd = sample(this.props.pool);
    }

    const imageElement = (
      <img
        className="Ad"
        alt="Publicidad"
        ref={el => (this.imageElement = el)}
        src={randomAd.imageUrl}
      />
    );

    // Not all Ads have an associated link.
    if (randomAd.websiteUrl) {
      return (
        <a href={randomAd.websiteUrl} target="_blank" rel="noopener noreferrer">
          {imageElement}
        </a>
      );
    }

    return imageElement;
  }
}
