// @flow
import React from 'react';
import pharmacyIcon from './pharmacy.svg';
import type { PharmacyType } from '../../types';
import './styles.css';

type Props = PharmacyType;

export default function Pharmacy(props: Props) {
  return (
    <div className="Pharmacy">
      <div className="Pharmacy__icon-container">
        <img src={pharmacyIcon} alt="Farmacia de Turno" className="Pharmacy__icon" />
      </div>

      <div className="Pharmacy__details">
        <div className="Pharmacy__heading">
          <span className="mute">Farmacia de Turno:</span> {props.name}
        </div>

        <ul className="Pharmacy__contact-details">
          <li className="Pharmacy__contact-details--address">{props.address}</li>
          <li className="Pharmacy__contact-details--phone">{props.phone}</li>
        </ul>

        <ul className="Pharmacy__dates">
          <li>Desde el {props.from}</li>
          <li>Hasta el {props.to}</li>
        </ul>
      </div>
    </div>
  );
}
