// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import find from 'lodash/find';
import get from 'lodash/get';
import Post from '../components/Post';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { showAlert } from '../actions/alerts';
import { ENDPOINT_GET_POST_BY_ID } from '../utils/constants';
import { parsePost } from '../utils/helpers';
import type { PostType, ReduxStateType, Dispatch } from '../types';

type Props = {
  posts: Array<PostType>,
  match: {
    params: {
      id: number
    }
  },
  showSpinner: () => void,
  hideSpinner: () => void,
  showAlert: (message: string, type: string) => void,
  history: {
    push: string => void
  }
};

type State = {
  post: ?PostType
};

class PostsContainer extends React.Component<Props, State> {
  constructor() {
    super();

    this.state = {
      post: null
    };
  }

  componentWillMount() {
    const postId = parseInt(this.props.match.params.id, 10);
    const post = find(this.props.posts, { id: postId });

    if (!!post) {
      this.setState({ post });
    } else {
      this.props.showSpinner();

      // If post doesnt exist, fetch it from API
      fetch(ENDPOINT_GET_POST_BY_ID + postId)
        .then(response => {
          const { status } = response;

          if (status !== 200) {
            return Promise.reject(status);
          }

          return response.json();
        })
        .then(data => {
          const post = parsePost(get(data, 'data', {}));

          this.setState({ post }, this.props.hideSpinner);
        })
        .catch(status => {
          let message;

          if (status === 404) {
            message = 'La noticia a la que intenta acceder no existe';
          } else {
            message = 'Ocurrió un error mientras intentábamos cargar la noticia';
          }

          this.props.hideSpinner();
          this.props.showAlert(message, 'error');
          this.props.history.push('/');
        });
    }
  }

  render() {
    return <Post {...this.state.post} />;
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    posts: state.posts
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ showSpinner, hideSpinner, showAlert }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsContainer);
