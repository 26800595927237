import { RECEIVE_PAGES } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PAGES: {
      return action.payload.pages;
    }

    default:
      return state;
  }
}
