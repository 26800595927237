// @flow
import './utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import get from 'lodash/get';
import App from './containers/App';
import store from './store';
import { unregister } from './utils/worker';
import { getEnvironment } from './utils/helpers';
import { API_ROOT_URL } from './utils/constants';
import './styles/index.css';

// Initialise and mount the application.
const hook = document.getElementById('root');

if (!hook) {
  throw new Error('Cannot mount application, please specify a valid DOM element.');
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  hook
);

// Removing the call that registers the service worker will prevent new registrations,
// and adding the next line will cause any existing service workers to unregister.
// https://github.com/facebook/create-react-app/issues/2715#issuecomment-313171863
unregister();

// Log bundle information
console.info(`Version: ${get(process.env, 'REACT_APP_VERSION', 'N/A')}`);
console.info(`Environment: ${getEnvironment()}`);
console.info(`API URL: ${API_ROOT_URL}`);
