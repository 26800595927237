// @flow

// Ads
export const RECEIVE_ADS = 'ADS/RECEIVE';

// Sticky Alert Messages
export const SHOW_ALERT = 'ALERT/SHOW';
export const HIDE_ALERT = 'ALERT/HIDE';

// Classifieds
export const RECEIVE_CLASSIFIEDS = 'CLASSIFIEDS/RECEIVE';

// Obituaries
export const RECEIVE_OBITUARIES = 'OBITUARIES/RECEIVE';

// Pages
export const RECEIVE_PAGES = 'PAGES/RECEIVE';

// Pagination
export const INCREMENT_PAGINATION_INDEX = 'PAGINATION/INCREMENT_INDEX';

// Pharmacy
export const RECEIVE_PHARMACY = 'PHARMACY/RECEIVE';

// Posts
export const RECEIVE_POSTS = 'POSTS/RECEIVE';

// Roster
export const RECEIVE_ROSTER = 'ROSTER/RECEIVE';

// Loading Spinner
export const SHOW_SPINNER = 'SPINNER/SHOW';
export const HIDE_SPINNER = 'SPINNER/HIDE';

// Weather
export const RECEIVE_WEATHER = 'WEATHER/RECEIVE';
