// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Contact from '../components/Contact';
import { ENDPOINT_CONTACT_US, EMAIL_ADDRESS } from '../utils/constants';
import { showSpinner, hideSpinner } from '../actions/spinner';
import { showAlert } from '../actions/alerts';
import type { Dispatch } from '../types';

type Props = {
  showSpinner: () => void,
  hideSpinner: () => void,
  showAlert: (message: string, type: string, autoClose?: boolean) => void
};

class ContactContainer extends React.Component<Props> {
  onSubmit = (name: string, email: string, message: string) => {
    const body =
      '<h1>Mensaje desde el Sitio Web</h1>' +
      '<p style="color:red;font-weight:bold;font-size:18px;">Por favor no responda directamente a este email.</p>' +
      '<ul style="font-size:18px;">' +
      `<li>Nombre: <strong>${name}</strong></li>` +
      `<li>Email: <strong>${email}</strong></li>` +
      '</ul>' +
      `<p style="font-size:18px;">${message}</p>`;

    const REQUEST_SETTINGS = {
      method: 'POST',
      body: JSON.stringify({
        from: 'no-reply@freyre.com.ar',
        to: EMAIL_ADDRESS,
        subject: 'Mensaje desde el Sitio Web',
        body
      }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    };

    this.props.showSpinner();

    fetch(ENDPOINT_CONTACT_US, REQUEST_SETTINGS)
      .then(response => response.json())
      .then(response => {
        // Hide loading spinner
        this.props.hideSpinner();

        // Display confirmation message
        this.props.showAlert(
          'Mensaje enviado correctamente. Gracias por ponerse en contacto con nosotros!',
          'success',
          false
        );

        // Redirect back to the homepage
        // browserHistory.push('/');
      })
      .catch((error: TypeError) => {
        // Log error to Rollbar
        console.error('CONTACT_FORM_ERROR', error);

        // Display user friendly error message
        this.props.showAlert(
          'Ocurrió un error al intentar enviar tu mensaje. Por favor, intenta de nuevo más tarde o contáctanos telefónicamente.',
          'error'
        );

        // Hide loading spinner
        // @TODO: move to Promise.finally when ready
        this.props.hideSpinner();
      });
  };

  render() {
    return <Contact onSubmit={this.onSubmit} />;
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ showSpinner, hideSpinner, showAlert }, dispatch);
}

export default connect(null, mapDispatchToProps)(ContactContainer);
