// @flow
import { SHOW_ALERT, HIDE_ALERT } from './types';
import type { ThunkAction, ShowAlertActionType, HideAlertActionType } from '../types';

// Time until the alert automatically closes, in milliseconds.
const ALERT_DURATION = 7000;

function _showAlert(id: number, message: string, type: string): ShowAlertActionType {
  return {
    type: SHOW_ALERT,
    payload: {
      id,
      message,
      type
    }
  };
}

export function hideAlert(id: number): HideAlertActionType {
  return {
    type: HIDE_ALERT,
    payload: {
      id
    }
  };
}

export function showAlert(message: string, type: string, autoClose: boolean = true): ThunkAction {
  return dispatch => {
    const alertId = Date.now();

    dispatch(_showAlert(alertId, message, type));

    // Close automatically after `ALERT_DURATION` ms.
    if (autoClose) {
      setTimeout(() => dispatch(hideAlert(alertId)), ALERT_DURATION);
    }
  };
}
