// @flow
import React from 'react';
import './styles.css';

type Props = {
  title?: string,
  body: string,
  className?: string
};

export default function Page(props: Props) {
  return (
    <article className={props.className}>
      {props.title && <h1 className="Page__title">{props.title}</h1>}

      {/* `props.body` is stringified HTML markup that comes from the API */}
      <div
        className="Page__body"
        dangerouslySetInnerHTML={{ __html: props.body }}
      />
    </article>
  );
}
