// @flow
import React from 'react';
import Entry from '../Entry';
import type { RosterEntryType } from '../../../types';
import './styles.css';

type Props = {
  entries: Array<RosterEntryType>
};

export default function Roster(props: Props) {
  return (
    <section className="Roster-List">
      {props.entries.map((details, index) => (
        <Entry key={index} {...details} className="Roster-List__entry" />
      ))}
    </section>
  );
}
