// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import canal from './canal.svg';
import coop from './coop.svg';
import { EMAIL_ADDRESS } from '../../utils/constants';
import './styles.css';

export default function Footer() {
  return (
    <footer className="Footer">
      <nav className="Footer__wrapper">
        <div className="Footer__column Footer__logo-canal-wrapper">
          <img src={canal} className="Footer__logo" alt="Canal Cooperativo Freyre" />
        </div>

        <div className={classnames('Footer__column', 'Footer__logo-coop-wrapper')}>
          <a href="http://coopfreyre.com.ar" target="_blank" rel="noopener noreferrer">
            <img
              src={coop}
              className="Footer__logo"
              alt="Cooperativa de Provisión de Servicios Públicos de Freyre Ltda."
            />
          </a>
        </div>

        <div className="Footer__column">
          <p className="Footer__column-title">Contáctenos</p>
          <ul className="Footer__column-list">
            <li>
              Teléfono: <a href="tel:+543564461585">(03564) 461585</a>
            </li>
            <li>
              Email: <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
            </li>
          </ul>
        </div>

        <div className="Footer__column">
          <p className="Footer__column-title">Servicios</p>
          <ul className="Footer__column-list">
            <li>
              <Link to="/turnos">Turnos y Emergencias</Link>
            </li>
            <li>
              <Link to="/clima">Estado del Tiempo</Link>
            </li>
          </ul>
        </div>

        <div className="Footer__column">
          <p className="Footer__column-title">Legal</p>
          <ul className="Footer__column-list">
            <li>
              <a
                href="http://static.freyre.com.ar/enacom/planilla-registro-publico.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Planilla ENACOM
              </a>
            </li>
            <li>
              <a
                href="http://static.freyre.com.ar/enacom/grilla.jpg"
                target="_blank"
                rel="noopener noreferrer"
              >
                Grilla de Señales
              </a>
            </li>
          </ul>
        </div>

        <div className="Footer__column">
          <a href="http://qr.afip.gob.ar/?qr=rJNnT7nTr8Rwh-VItd68xA,," target="_F960AFIPInfo">
            <img
              src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
              alt="Data Fiscal AFIP"
              className="Footer__qr"
            />
          </a>
        </div>
      </nav>
    </footer>
  );
}
