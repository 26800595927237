import water from './water.svg';
import power from './power.svg';
import internet from './internet.svg';
import blood from './blood.svg';

export default {
  'Agua y Cloacas': water,
  'Energía Eléctrica': power,
  'Televisión e Internet': internet,
  'Banco de Sangre': blood
};
