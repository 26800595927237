// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import find from 'lodash/find';
import Roster from '../components/Roster';
import { fetchPages } from '../actions/pages';
import { fetchRoster } from '../actions/roster';
import { fetchPharmacy } from '../actions/pharmacy';
import type { PageType, RosterEntryType, PharmacyType, ReduxStateType, Dispatch } from '../types';

type Props = {
  fetchPages: () => void,
  fetchRoster: () => void,
  fetchPharmacy: () => void,
  pages: Array<PageType>,
  roster: Array<RosterEntryType>,
  pharmacy: ?PharmacyType
};

class RosterContainer extends React.Component<Props> {
  componentWillMount() {
    if (this.props.pages.length === 0) {
      this.props.fetchPages();
    }

    if (this.props.roster.length === 0) {
      this.props.fetchRoster();
    }

    if (!this.props.pharmacy) {
      this.props.fetchPharmacy();
    }
  }

  render() {
    const page = find(this.props.pages, { slug: 'telefonos' });

    return <Roster page={page} pharmacy={this.props.pharmacy} roster={this.props.roster} />;
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    pages: state.pages,
    roster: state.roster,
    pharmacy: state.pharmacy
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ fetchPages, fetchRoster, fetchPharmacy }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterContainer);
