// @flow
import React from 'react';
import { connect } from 'react-redux';
import Weather from '../components/Weather';
import type { WeatherType, ReduxStateType } from '../types';

type Props = {
  weather: WeatherType
};

function WeatherContainer(props: Props) {
  return <Weather weather={props.weather} />;
}

function mapStateToProps(state: ReduxStateType) {
  return {
    weather: state.weather
  };
}

export default connect(mapStateToProps)(WeatherContainer);
