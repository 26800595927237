// @flow
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

// Middleware.
const middleware = [thunk];

// Enable Redux DevTools on development only.
const composeEnhancers = composeWithDevTools({});

// Instantiate Redux store.
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
