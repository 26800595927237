// @flow
import React, { Fragment } from 'react';
import Hero from '../Hero';
import Container from '../Container';
import NoResults from '../NoResults';
import Obituary from './Obituary';
import type { ObituaryType } from '../../types';
import './styles.css';

type Props = {
  obituaries: Array<ObituaryType>
};

export default function Obituaries(props: Props) {
  return (
    <Fragment>
      <Hero title="Necrológicos" background="#5f5f5f" />
      <Container className="Obituaries__content">
        {props.obituaries.length === 0 ? (
          <NoResults />
        ) : (
          props.obituaries.map((obituary, index) => (
            <div className="Obituaries__entry">
              <Obituary key={index} {...obituary} />
            </div>
          ))
        )}
      </Container>
    </Fragment>
  );
}
