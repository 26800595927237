// @flow
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import type { Item } from '../Dropdown';
import './styles.css';

type Props = {
  items: Array<Item>,
  className?: string,
  expanded: boolean,
  collapseDropdown: () => void,
  collapseMenu: () => void
};

export default function Popover(props: Props) {
  const handlePointerDown = () => {
    props.collapseDropdown();
    props.collapseMenu();
  };

  return (
    props.expanded && (
      <ul className={classnames('Popover', props.className)}>
        {props.items.map((item, index) => {
          if (item.isExternal) {
            return (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="Popover__link"
                onClick={handlePointerDown}
              >
                {item.title}
              </a>
            );
          } else {
            return (
              <Link key={index} to={item.url} className="Popover__link" onClick={handlePointerDown}>
                {item.title}
              </Link>
            );
          }
        })}
      </ul>
    )
  );
}
