// @flow
import get from 'lodash/get';
import { showAlert } from './alerts';
import { RECEIVE_WEATHER } from './types';
import {
  ENDPOINT_GET_WEATHER,
  ENDPOINT_LOCAL_WEATHER_STATION,
  ORIGIN_LOCAL_WEATHER_STATION
} from '../utils/constants';
import type { WeatherCurrentConditionsType, ReceiveWeatherActionType, ThunkAction } from '../types';

function receiveWeather(currentConditions: WeatherCurrentConditionsType): ReceiveWeatherActionType {
  return {
    type: RECEIVE_WEATHER,
    payload: {
      currentConditions
    }
  };
}

export function fetchWeather(): ThunkAction {
  return (dispatch, getState) => {
    const settings = {
      headers: {
        Origin: ORIGIN_LOCAL_WEATHER_STATION
      }
    };
    const localStationPromise = fetch(ENDPOINT_LOCAL_WEATHER_STATION, settings)
      .then(response => response.text())
      .catch(() => null);
    const weatherPromise = fetch(ENDPOINT_GET_WEATHER)
      .then(response => response.json())
      .then(response => get(response, 'data.currentConditions'))
      .catch(() => ({}));

    Promise.all([weatherPromise, localStationPromise])
      .then(values => {
        const [currentConditions, imageSrc] = values;

        // Min and max temperatures can be `null`. Make sure we don't render the string `null`.
        // Instead, render a dash if these values are not available.
        const rawMinTemperature = get(currentConditions, 'temperature.min.value');
        const rawMaxTemperature = get(currentConditions, 'temperature.max.value');
        const minTemperature = rawMinTemperature ? `${rawMinTemperature}°` : '-';
        const maxTemperature = rawMaxTemperature ? `${rawMaxTemperature}°` : '-';

        // Cummulative rainfall can be null.
        // Make sure we render a user friendly message in those cases.
        const rawDayRainfall = get(currentConditions, 'cumulativeRainfall.day.value');
        const rawWeekRainfall = get(currentConditions, 'cumulativeRainfall.week.value');
        const rawMonthRainfall = get(currentConditions, 'cumulativeRainfall.month.value');
        const dayRainfall = rawDayRainfall ? `${rawDayRainfall} mm` : 'No disponible';
        const weekRainfall = rawWeekRainfall ? `${rawWeekRainfall} mm` : 'No disponible';
        const monthRainfall = rawMonthRainfall ? `${rawMonthRainfall} mm` : 'No disponible';

        // Wind speed and gusts.
        const windSpeed = get(currentConditions, 'wind.speed.value');
        const gustSpeed = get(currentConditions, 'wind.gust.value');
        const windAndGustSpeed = `${windSpeed} km/h con ráfagas de ${gustSpeed} km/h`;

        // Parse API response to the format we need.
        const parsedCurrentConditions: WeatherCurrentConditionsType = {
          lastUpdate: get(currentConditions, 'updatedAt'),
          currentTemperature: get(currentConditions, 'temperature.current.value') + '°',
          minTemperature,
          maxTemperature,
          humidity: get(currentConditions, 'humidity.value') + '%',
          pressure: `${get(currentConditions, 'pressure.value')} hPa`,
          dayRainfall,
          weekRainfall,
          monthRainfall,
          windSpeed: windAndGustSpeed,
          iconUrl: get(currentConditions, 'icon.url'),
          iconDescription: get(currentConditions, 'icon.description'),
          imageSrc: imageSrc && `data:image/jpeg;base64,${imageSrc}`
        };

        dispatch(receiveWeather(parsedCurrentConditions));
      })
      .catch((error: TypeError) => {
        // Display a user friendly message on the UI.
        dispatch(
          showAlert('Ocurrió un error mientras intentábamos cargar el estado del clima.', 'error')
        );

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch weather data from the API`, error);
      });
  };
}
