// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import symbol from './symbol.svg';
import logotype from './logotype.svg';
import './styles.css';

type Props = {
  className?: string
};

export default function Logo(props: Props) {
  const logoClassName = classnames('Logo', props.className);

  return (
    <Link to="/" className={logoClassName}>
      <img src={symbol} className="Logo__symbol" alt="" />
      <img src={logotype} className="Logo__logotype" alt="" />
    </Link>
  );
}
