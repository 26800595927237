// @flow
import React from 'react';
import type { ObituaryType } from '../../../types';
import './styles.css';

type Props = ObituaryType;

export default function Obituary(props: Props) {
  return (
    <ul className="Obituary">
      <li className="Obituary__name">{props.name}</li>
      <li className="Obituary__age">
        <span className="Obituary__heading">Edad:</span> {props.age}
      </li>
      <li className="Obituary__service">
        <span className="Obituary__heading">Sepelio:</span> {props.service}
      </li>
      {props.details && <li className="Obituary__details">{props.details}</li>}
    </ul>
  );
}
