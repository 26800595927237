import { RECEIVE_ROSTER } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_ROSTER: {
      return action.payload.roster;
    }

    default:
      return state;
  }
}
