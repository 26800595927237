// @flow
import { RECEIVE_CLASSIFIEDS } from './types';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { parseDate } from '../utils/helpers';
import { ENDPOINT_GET_CLASSIFIEDS } from '../utils/constants';
import type {
  ClassifiedType,
  ReceiveClassifiedsActionType,
  ClassifiedsResponseType,
  ThunkAction
} from '../types';

function receiveClassifieds(classifieds: Array<ClassifiedType>): ReceiveClassifiedsActionType {
  return {
    type: RECEIVE_CLASSIFIEDS,
    payload: {
      classifieds
    }
  };
}

export function fetchClassifieds(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    fetch(ENDPOINT_GET_CLASSIFIEDS)
      .then(response => response.json())
      .then((response: ClassifiedsResponseType) => {
        const classifieds = response.data.map(classified => ({
          title: classified.title,
          description: classified.description,
          contact: classified.contact,
          imageUrl: classified.image_url,
          date: parseDate(classified.from)
        }));

        dispatch(receiveClassifieds(classifieds));
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(
          showAlert(
            'Ocurrió un error mientras intentábamos cargar los avisos clasificados.',
            'error'
          )
        );
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch classifieds from the API`, error);
      });
  };
}
