// @flow
import get from 'lodash/get';
import format from 'date-fns/format';
import spanishLocale from 'date-fns/locale/es';
import type { PostType, RawPostType } from '../types';

export function isURL(url: string): boolean {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

  return regexp.test(url);
}

export function isEmail(email: string): boolean {
  const regexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  return regexp.test(email);
}

// @TODO: not sure how to type this function
export function getVideoId(url: string): string {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[7].length === 11 ? match[7] : '';
}

export function stripHtmlTags(markup: string = ''): string {
  const tmp = document.createElement('div');

  tmp.innerHTML = markup;

  return tmp.textContent || '';
}

export function cleanUpMarkup(markup: string): string {
  // Empty paragraphs from our WYSIWYG editor come as: \n<p><br></p>
  const regexpEmptyParagraphs = /\n<p><br><\/p>/g;

  // Breaklines from our WYSIWYG editor come as: <br> &nbsp;</p>
  const regexpBreakLines = /<br>\n&nbsp;<\/p>/g;

  // Links
  const regexpLinksBeginning = /<a\b[^>]*>/i;
  const regexpLinksEnd = /<\/a>/i;

  return markup
    .replace(regexpEmptyParagraphs, '')
    .replace(regexpBreakLines, '</p>')
    .replace(regexpLinksBeginning, '')
    .replace(regexpLinksEnd, '');
}

function isCodeOptimized(): boolean {
  return process.env.NODE_ENV === 'production';
}

function isProductionEnvironment(): boolean {
  return isCodeOptimized() && process.env.REACT_APP_ENV === 'production';
}

function isStagingEnvironment(): boolean {
  return isCodeOptimized() && process.env.REACT_APP_ENV === 'staging';
}

function isDevelopmentEnvironment(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function getEnvironment(): string {
  if (isProductionEnvironment()) {
    return 'production';
  } else if (isStagingEnvironment()) {
    return 'staging';
  } else if (isDevelopmentEnvironment()) {
    return 'development';
  } else {
    throw Error('Invalid environment');
  }
}

/*
 * Parses UTC datetime strings into human-friendly dates in Spanish and into the local timezone.
 * e.g. 2018-09-09T16:00:00.000Z -> domingo 9 de septiembre de 2018 a las 13:00 hs.
 */
export function parseDateTime(datetime: string) {
  return format(datetime, 'dddd D [de] MMMM [de] YYYY [a las] HH:mm [hs.]', {
    locale: spanishLocale
  });
}

/*
 * Parses UTC datetime strings into human-friendly dates in Spanish and into the local timezone (date only, no time).
 * e.g. 2018-09-09T16:00:00.000Z -> domingo 9 de septiembre de 2018
 */
export function parseDate(datetime: string) {
  return format(datetime, 'dddd D [de] MMMM [de] YYYY', {
    locale: spanishLocale
  });
}

/*
 * Parses a raw post from the API to the format we need within the App.
 * We keep this logic here b/c we use it in both `actions/posts.js` and `containers/Posts.js`.
 */
export function parsePost(rawPost: RawPostType): PostType {
  return {
    id: rawPost.id,
    title: rawPost.title,
    excerpt: rawPost.excerpt,
    body: cleanUpMarkup(rawPost.body),
    categoryName: get(rawPost, 'category.name'),
    categoryColor: get(rawPost, 'category.color'),
    date: parseDate(rawPost.created_at),
    media: get(rawPost, 'media', []).map(mediaItem => ({
      type: mediaItem.type,
      url: mediaItem.url,
      details: mediaItem.details
    }))
  };
}
