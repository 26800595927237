// @flow
import React from 'react';
import { isEmail } from '../../../utils/helpers';
import './styles.css';

type Props = {
  className?: string,
  onSubmit: (name: string, email: string, message: string) => void
};

type State = {
  name: string,
  email: string,
  message: string,
  errors: Array<string>
};

export default class Form extends React.Component<Props, State> {
  state = {
    name: '',
    email: '',
    message: '',
    errors: []
  };

  validate = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const { name, email, message } = this.state;
    const { onSubmit } = this.props;
    const errors = [];

    // Validate all fields
    if (!name) {
      errors.push('Por favor, escriba su nombre y apellido.');
    }

    if (!isEmail(email)) {
      errors.push('Por favor, ingrese un email válido.');
    }

    if (!message) {
      errors.push('Por favor, escriba un mensaje.');
    }

    if (errors.length === 0) {
      // POST the form
      onSubmit(name, email, message);
    } else {
      // Display the errors
      this.setState({
        errors
      });
    }
  };

  onNameChange = (event: SyntheticInputEvent<Text>) => {
    const name = event.target.value;

    this.setState({
      name
    });
  };

  onEmailChange = (event: SyntheticInputEvent<Text>) => {
    const email = event.target.value;

    this.setState({
      email
    });
  };

  onMessageChange = (event: SyntheticInputEvent<Text>) => {
    const message = event.target.value;

    this.setState({
      message
    });
  };

  renderErrors = () => {
    return (
      this.state.errors.length > 0 && (
        <ul className="Form__errors">
          {this.state.errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )
    );
  };

  render() {
    const { name, email, message } = this.state;

    return (
      <form method="POST" className={this.props.className}>
        {// Render validation/compulsory fields errors
        this.renderErrors()}

        {/* Name */}
        <label className="Form__field">
          Nombre y Apellido:
          <input
            type="text"
            placeholder="Escriba su nombre y apellido"
            value={name}
            onChange={this.onNameChange}
            required
          />
        </label>

        {/* Email Address */}
        <label className="Form__field">
          Dirección de Email:
          <input
            type="email"
            placeholder="Escriba su dirección de correo electrónico"
            value={email}
            onChange={this.onEmailChange}
            required
          />
        </label>

        {/* Message */}
        <label className="Form__field">
          Comentarios:
          <textarea
            placeholder="Escriba sus comentarios"
            value={message}
            onChange={this.onMessageChange}
            required
          />
        </label>

        {/* Submit button */}
        <button className="Form__submit" onClick={this.validate}>
          Enviar Mensaje
        </button>
      </form>
    );
  }
}
