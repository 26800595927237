import { RECEIVE_CLASSIFIEDS } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CLASSIFIEDS: {
      return action.payload.classifieds;
    }

    default:
      return state;
  }
}
