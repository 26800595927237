// @flow
import * as React from 'react';
import classnames from 'classnames';
import './styles.css';

type Props = {
  className?: string,
  children?: React.Node
};

export default function Container(props: Props) {
  return (
    <section className={classnames('Container', props.className)}>
      {props.children}
    </section>
  );
}
