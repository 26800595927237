// @flow
import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import './styles.css';

export default function Header() {
  return (
    <header className="Header">
      <div className="Header__content">
        <Logo className="Header__logo" />
        <Navigation />
      </div>
    </header>
  );
}
