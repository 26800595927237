// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPosts } from '../actions/posts';
import NewsFeed from '../components/NewsFeed';
import type { PostType, AdType, ReduxStateType, Dispatch } from '../types';

type Props = {
  ads: Array<AdType>,
  posts: Array<PostType>,
  fetchPosts: () => void
};

const NewsFeedContainer = (props: Props) => (
  <NewsFeed posts={props.posts} ads={props.ads} onLoadMore={props.fetchPosts} />
);

function mapStateToProps(state: ReduxStateType) {
  return {
    posts: state.posts,
    ads: state.ads
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ fetchPosts }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsFeedContainer);
