import { RECEIVE_OBITUARIES } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_OBITUARIES: {
      return action.payload.obituaries;
    }

    default:
      return state;
  }
}
