// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchObituaries } from '../actions/obituaries';
import Obituaries from '../components/Obituaries';
import type { ObituaryType, ReduxStateType, Dispatch } from '../types';

type Props = {
  fetchObituaries: () => void,
  obituaries: Array<ObituaryType>
};

class ObituariesContainer extends React.Component<Props> {
  componentWillMount() {
    if (this.props.obituaries.length === 0) {
      this.props.fetchObituaries();
    }
  }

  render() {
    return <Obituaries obituaries={this.props.obituaries} />;
  }
}

function mapStateToProps(state: ReduxStateType) {
  return {
    obituaries: state.obituaries
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ fetchObituaries }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObituariesContainer);
