// @flow
import React, { Fragment } from 'react';
import Container from '../Container';
import Hero from '../Hero';
import Page from '../Page';
import './styles.css';
import ReactHlsPlayer from 'react-hls-player';

type Props = {
  content: string
};

export default function Live(props: Props) {
  return (
    <Fragment>
      <Hero title="En vivo" subtitle="" background="#CC0000" />
      <Container className="Live">
        <ReactHlsPlayer
          src="https://online.coopmorteros.coop/freyre/freyre.m3u8"
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </Container>{' '}
    </Fragment>
  );
}
