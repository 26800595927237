// @flow
import React from 'react';
import icon from './no-results.svg';
import './styles.css';

export default function NoResults() {
  return (
    <figure className="No-Results">
      <img src={icon} alt="No hay resultados" className="No-Results__image" />
      <figcaption className="No-Results__caption">No hay resultados</figcaption>
    </figure>
  );
}
