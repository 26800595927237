// @flow
import React from 'react';
import Roundabout from 'react-roundabout';
import Video from 'react-youtube';

// We need to import both the local and the library styles
import './styles.css';
import 'react-roundabout/lib/roundabout.css';

type MediaItem = {
  type: 'image' | 'video' | 'audio',
  url: string,
  details: {
    id: string | number
  }
};

type Props = {
  slides: Array<MediaItem>
};

export default function Carousel(props: Props) {
  if (props.slides.length > 1) {
    return (
      <Roundabout loop={true}>
        {props.slides.map((slide, index) => {
          // Image
          if (slide.type === 'image') {
            return (
              <div key={index} className="Carousel__slide">
                <a href={slide.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={slide.url}
                    className="Carousel__image--slide"
                    alt={`Imagen #${index + 1} asociada a la noticia`}
                  />
                </a>
              </div>
            );
          } else {
            // Video
            return (
              <div key={index} className="Carousel__slide">
                <Video videoId={slide.details.id} className="Carousel__video--slide" />
              </div>
            );
          }
        })}
      </Roundabout>
    );
  } else {
    const slide = props.slides[0];

    if (slide.type === 'image') {
      return (
        <a href={slide.url} target="_blank" rel="noopener noreferrer">
          <img
            src={slide.url}
            className="Carousel__image--standalone"
            alt="Imagen ilustrativa asociada a la noticia"
          />
        </a>
      );
    } else if (slide.type === 'video') {
      return <Video videoId={slide.details.id} className="Carousel__video--standalone" />;
    }
  }
}

Carousel.defaultProps = {
  slides: []
};
