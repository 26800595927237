// @flow
import React, { Fragment } from 'react';
import Container from '../Container';
import Hero from '../Hero';
import Page from '../Page';
import './styles.css';

type Props = {
  content: string
};

export default function About(props: Props) {
  return (
    <Fragment>
      <Hero
        title="Institucional"
        subtitle="Reseña histórica de Canal Cooperativo Freyre"
        background="#745198"
      />

      <Container className="About__content">
        <Page body={props.content} />
      </Container>
    </Fragment>
  );
}
