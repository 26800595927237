// @flow
import React from 'react';
import facebookIcon from './facebook.svg';
import twitterIcon from './twitter.svg';
import whatsAppIcon from './whatsapp.svg';
import emailIcon from './email.svg';
import './styles.css';

type Props = {
  title: string,
  url: string
};

function openModal(url: string) {
  window.open(
    url,
    'share',
    'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=300'
  );
}

export default function Share(props: Props) {
  const encodedTitle = window.encodeURIComponent(props.title);
  const encodedUrl = window.encodeURIComponent(props.url);

  // Share URLs
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterShareUrl = `https://twitter.com/share?url=${encodedUrl}&text=${encodedTitle}`;
  const whatsAppShareUrl = `whatsapp://send?text=${encodedTitle} ${encodedUrl}`;
  const emailShareUrl = `mailto:?subject=${
    props.title
  }&body=Noticia compartida desde la web de Canal Cooperativo Freyre. Para leerla haga click en el siguiente link: ${
    props.url
  }`;

  return (
    <nav className="Share">
      <button className="Share__link" onClick={() => openModal(facebookShareUrl)}>
        <img
          src={facebookIcon}
          className="Share__icon"
          alt="Compartir en Facebook"
          title="Compartir en Facebook"
        />
      </button>

      <button className="Share__link" onClick={() => openModal(twitterShareUrl)}>
        <img
          src={twitterIcon}
          className="Share__icon"
          alt="Compartir en Twitter"
          title="Compartir en Twitter"
        />
      </button>

      <button className="Share__link" onClick={() => openModal(whatsAppShareUrl)}>
        <img
          src={whatsAppIcon}
          className="Share__icon"
          alt="Enviar por WhatsApp"
          title="Enviar por WhatsApp"
        />
      </button>

      <button
        className="Share__link"
        href={emailShareUrl}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={emailIcon}
          className="Share__icon"
          alt="Enviar por Email"
          title="Enviar por Email"
        />
      </button>
    </nav>
  );
}
