// @flow
import React from 'react';
import type { ClassifiedType } from '../../../types';
import './styles.css';

type Props = ClassifiedType;

export default function Classified(props: Props) {
  return (
    <div className="Classified">
      <ul className="Classified__details">
        <li className="Classified__details-title">{props.title}</li>
        <li className="Classified__details-description">{props.description}</li>
        {props.contact && <li className="Classified__details-contact">— {props.contact}</li>}
        <li className="Classified__details-date">Publicado el {props.date}</li>
      </ul>

      {props.imageUrl && (
        <a href={props.imageUrl} target="_blank" rel="noreferrer noopener">
          <img src={props.imageUrl} alt={props.title} className="Classified__image" />
        </a>
      )}
    </div>
  );
}
