// @flow
import { RECEIVE_OBITUARIES } from './types';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { parseDateTime } from '../utils/helpers';
import { ENDPOINT_GET_OBITUARIES } from '../utils/constants';
import type {
  ObituaryType,
  ReceiveObituariesActionType,
  ObituariesResponseType,
  ThunkAction
} from '../types';

function receiveObituaries(obituaries: Array<ObituaryType>): ReceiveObituariesActionType {
  return {
    type: RECEIVE_OBITUARIES,
    payload: {
      obituaries
    }
  };
}

export function fetchObituaries(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    fetch(ENDPOINT_GET_OBITUARIES)
      .then(response => response.json())
      .then((response: ObituariesResponseType) => {
        const obituaries = response.data.map(obituary => ({
          name: obituary.name,
          age: obituary.age,
          details: obituary.details,
          service: obituary.service ? parseDateTime(obituary.service) : 'A confirmar'
        }));

        dispatch(receiveObituaries(obituaries));
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(
          showAlert(
            'Ocurrió un error mientras intentábamos cargar los avisos necrológicos.',
            'error'
          )
        );
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch obituaries from the API`, error);
      });
  };
}
