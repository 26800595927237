// @flow
import React from 'react';
import classnames from 'classnames';
import Popover from '../Popover';
import { onPointerDownEventName } from '../../../utils/pointerEvents';
import './styles.css';

export type Item = {
  title: string,
  url: string,
  isExternal: boolean
};

type Props = {
  className?: string,
  children: string,
  items: Array<Item>,
  collapseMenu: () => void
};

type State = {
  expanded: boolean
};

export default class Dropdown extends React.Component<Props, State> {
  state = {
    expanded: false
  };

  dropdownElement = null;

  componentWillMount = () => {
    document.addEventListener('keydown', this.onEscapeKeyPress);
    document.addEventListener(onPointerDownEventName, this.onBlur);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.onEscapeKeyPress);
    document.removeEventListener(onPointerDownEventName, this.onBlur);
  };

  // Close popover if the user presses the Escape key.
  onEscapeKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.collapse();
    }
  };

  // Close popover if the user clicks or taps outside the dropdown menu.
  onBlur = (event: any) => {
    const hasClickedOutside =
      !!this.dropdownElement && !this.dropdownElement.contains(event.target);

    if (this.state.expanded && hasClickedOutside) {
      this.collapse();
    }
  };

  expand = () => {
    this.setState({
      expanded: true
    });
  };

  collapse = () => {
    this.setState({
      expanded: false
    });
  };

  render() {
    return (
      <span className="Dropdown" ref={el => (this.dropdownElement = el)}>
        <button
          className={classnames('Dropdown__button', this.props.className)}
          onClick={this.expand}
        >
          {this.props.children}
        </button>

        <Popover
          className="Dropdown__popover"
          items={this.props.items}
          expanded={this.state.expanded}
          collapseMenu={this.props.collapseMenu}
          collapseDropdown={this.collapse}
        />
      </span>
    );
  }
}
