// @flow
type PointerDownEventName = 'pointerdown' | 'touchstart' | 'mousedown';

const supportsPointerEvents = !!window.PointerEvent;
const supportsTouchEvents =
  'ontouchstart' in window || (!!window.DocumentTouch && document instanceof window.DocumentTouch);

function getSupportedOnPointerDownEventName(): PointerDownEventName {
  if (supportsPointerEvents) {
    return 'pointerdown';
  } else if (supportsTouchEvents) {
    return 'touchstart';
  } else {
    return 'mousedown';
  }
}

export const onPointerDownEventName = getSupportedOnPointerDownEventName();
