import assign from 'lodash/assign';
import { RECEIVE_POSTS } from '../actions/types';
import { cleanUpMarkup } from '../utils/helpers';

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_POSTS: {
      const newPosts = action.payload.posts.map(post =>
        assign({}, post, {
          body: cleanUpMarkup(post.body)
        })
      );

      return state.concat(newPosts);
    }

    default:
      return state;
  }
}
