// @flow
import includes from 'lodash/includes';
import { RECEIVE_ADS } from './types';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { ENDPOINT_GET_ADS } from '../utils/constants';
import type { AdType, ReceiveAdsActionType, AdsResponseType, ThunkAction } from '../types';

const WEB_AD_TYPES = ['web-sm', 'web-lg', 'all'];

function receiveAds(ads: Array<AdType>): ReceiveAdsActionType {
  return {
    type: RECEIVE_ADS,
    payload: {
      ads
    }
  };
}

export function fetchAds(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    fetch(ENDPOINT_GET_ADS)
      .then(response => response.json())
      .then((response: AdsResponseType) => {
        const ads = response.data
          .filter(ad => includes(WEB_AD_TYPES, ad.type.value))
          .map(ad => ({
            type: ad.type.value,
            imageUrl: ad.image_url,
            websiteUrl: ad.website_url
          }));

        dispatch(receiveAds(ads));
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(
          showAlert('Ocurrió un error mientras intentábamos cargar las publicidades.', 'error')
        );
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch ads from the API`, error);
      });
  };
}
