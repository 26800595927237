import { RECEIVE_PHARMACY } from '../actions/types';

const initialState = null;

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PHARMACY: {
      return action.payload.pharmacy;
    }

    default:
      return state;
  }
}
