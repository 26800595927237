// @flow
import React from 'react';
import classnames from 'classnames';
import { isURL } from '../../../utils/helpers';
import './styles.css';

type Props = {
  background?: string,
  title: string,
  excerpt: string,
  category: string
};

export default function Preview(props: Props) {
  // Display a background image iff:
  // 1) if a background image got provided
  // 2) if it's a valid URL
  const hasBackground = props.background && isURL(props.background);
  const className = classnames('Preview', {
    'Preview--image': hasBackground,
    'Preview--text': !hasBackground
  });

  if (hasBackground) {
    return (
      <article
        className={className}
        style={{
          backgroundImage: props.background && `url(${props.background})`
        }}
      >
        <div className="Preview__title--image">{props.title}</div>
        <div className="Preview__excerpt--image">{props.excerpt}</div>
      </article>
    );
  } else {
    return (
      <article className={className}>
        <span className="Preview__category--text">{props.category}</span>
        <p className="Preview__title--text">{props.title}</p>
        <p className="Preview__excerpt--text">{props.excerpt}</p>
      </article>
    );
  }
}
