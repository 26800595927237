// @flow
import * as React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Alert from '../Alert';
import Spinner from '../Spinner';
import type { WeatherType, AlertType } from '../../types';
import './styles.css';

type Props = {
  children?: React.Node,
  alerts: Array<AlertType>,
  weather: WeatherType,
  spinner: boolean,
  hideAlert: (id: number) => void
};

export default function App(props: Props) {
  return (
    <section>
      <Header />
      {props.children}
      <Footer />

      <Spinner isVisible={props.spinner} />

      <div className="App__alerts">
        {props.alerts.map(alert => (
          <Alert
            key={alert.id}
            type={alert.type}
            onClose={() => props.hideAlert(alert.id)}
          >
            {alert.message}
          </Alert>
        ))}
      </div>
    </section>
  );
}
