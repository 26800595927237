// @flow
import React from 'react';
import classnames from 'classnames';
import homeIcon from './home.svg';
import phoneIcon from './phone.svg';
import emailIcon from './email.svg';
import './styles.css';

type Props = {
  className?: string
};

export default function ContactDetails(props: Props) {
  return (
    <ul className={classnames('Details', props.className)}>
      <li className="Details__item">
        <img
          src={homeIcon}
          title="Dirección"
          alt="Dirección"
          className="Details__item-icon"
        />
        <span className="Details__item-label">Dirección</span>
        Bv. 25 de Mayo 880
      </li>

      <li className="Details__item">
        <img
          src={phoneIcon}
          title="Teléfono"
          alt="Teléfono"
          className="Details__item-icon"
        />
        <span className="Details__item-label">Teléfono</span>
        <a href="tel:+543564461585">(03564) 461585</a>
      </li>

      <li className="Details__item">
        <img
          src={emailIcon}
          title="Email"
          alt="Email"
          className="Details__item-icon"
        />
        <span className="Details__item-label">Email</span>
        <a href="mailto:canal6@freyre.com.ar">canal6@freyre.com.ar</a>
      </li>
    </ul>
  );
}
