// @flow
import { SHOW_SPINNER, HIDE_SPINNER } from './types';
import type { ShowSpinnerActionType, HideSpinnerActionType } from '../types';

export function showSpinner(): ShowSpinnerActionType {
  return {
    type: SHOW_SPINNER
  };
}

export function hideSpinner(): HideSpinnerActionType {
  return {
    type: HIDE_SPINNER
  };
}
