// @flow
import { RECEIVE_ROSTER } from './types';
import { showSpinner, hideSpinner } from './spinner';
import { showAlert } from './alerts';
import { ENDPOINT_GET_ROSTER } from '../utils/constants';
import type {
  RosterEntryType,
  ReceiveRosterActionType,
  RosterResponseType,
  ThunkAction
} from '../types';

function receiveRoster(roster: Array<RosterEntryType>): ReceiveRosterActionType {
  return {
    type: RECEIVE_ROSTER,
    payload: {
      roster
    }
  };
}

export function fetchRoster(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(showSpinner());

    fetch(ENDPOINT_GET_ROSTER)
      .then(response => response.json())
      .then((response: RosterResponseType) => {
        const roster = response.data.map(rosterEntry => ({
          activity: rosterEntry.activity,
          phone: rosterEntry.phone
        }));

        dispatch(receiveRoster(roster));
        dispatch(hideSpinner());
      })
      .catch((error: TypeError) => {
        dispatch(showAlert('Ocurrió un error mientras intentábamos cargar los turnos.', 'error'));
        dispatch(hideSpinner());

        // The actual error message will get logged to the console and also reported by Rollbar.
        console.error(`Couldn't fetch roster from the API`, error);
      });
  };
}
